/**
 *
 * Report
 *
 */
import * as React from 'react';
import { get, isEqual } from 'lodash';
import { useReportSlice } from './slice';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector, batch } from 'react-redux';
import {
  Box,
  Button,
  Container,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import { selectAllReports, selectExport } from './slice/selectors';
import { usePrevious } from '../../../utils/usePrevious';
import { DataTable } from './DataTable';
import {
  GridSortDirection,
  GridSortModel,
  LicenseInfo,
} from '@mui/x-data-grid-pro';
import { lineFieldActions } from 'app/containers/LineAdjustmentManager/slice';
import { ReportGraphic } from './ReportGraphic';
import { useLocation } from 'react-router';
import { useDebounce } from '../../../utils/useDebounce';
import { FilterBar } from '../FilterBar';
import { useQuery } from '../../../utils/query';
import { selectConfirmAdjustment } from '../LineAdjustmentManager/slice/selectors';
import { useCustomerSelector } from '../CustomerSelector/useCustomerSelector';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { Refresh } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
import { serveFile } from '../../../utils/request';

interface Props {
  id: string;
  type: 'full' | 'abridged';
}

export function Report(props: Props) {
  const { actions } = useReportSlice();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { search } = useLocation();
  const reports = useSelector(selectAllReports);
  const exportData = useSelector(selectExport);
  const confirmAdjustment = useSelector(selectConfirmAdjustment);
  const report = reports[props.id];
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [pageSize, setPageSize] = React.useState<number>(100);
  const [page, setPage] = React.useState<number>(1);
  const [sort, setSort] = React.useState<
    [field: string, direction?: GridSortDirection] | undefined
  >(undefined);

  const [reportSettings, setReportSettings] = React.useState<{
    [key: string]: any;
  }>(get(location, 'state.reportSettings', { reload: false }));

  React.useEffect(() => {
    setTimeout(() => dispatch(actions.reportMount({ id: props.id })), 1);
    return () => {
      dispatch(actions.reportMount({ id: props.id }));
    };
  }, [dispatch]);

  React.useEffect(() => {
    if (reportSettings.reload) {
      setReportSettings({ ...reportSettings, reload: false });
      loadReport('table');
    }
  }, [reportSettings]);

  React.useEffect(() => {
    if (!!report) loadReport();
  }, [!!report]);

  const loadReport = (type: 'graphic' | 'table' = 'graphic') => {
    if (!report) return;
    if (!reportSettings)
      setReportSettings(get(report, 'data.graphic.reportSettings', {}));

    const action =
      !loaded ||
      type === 'graphic' ||
      !report.graphic.data ||
      report.graphic.loading
        ? actions.reportRequest
        : actions.reportDataRequest;

    setLoaded(true);

    dispatch(
      action({
        reportSettings,
        id: props.id,
        type: props.type,
        filters,
        page: { number: page, size: pageSize },
        sort: sort,
      }),
    );
  };

  const changePage = (number: number, size: number) => {
    setPage(number);
    if (size) {
      setPageSize(size);
    }
  };

  const { query, setQuery } = useQuery();
  const getFilters = () => query.filters;
  const [filters, setFilters] = React.useState(getFilters());

  const loadFullReport = (settings = null) => {
    if (props.type !== 'full') {
      const to = [
        {
          pathname: `/portal/report/full/${props.id}`,
          search: location.search,
        },
        { reportSettings: settings || reportSettings },
      ];
      history.push(to[0], to[1]);
    }
  };

  React.useEffect(() => {
    setFilters(getFilters());
  }, [search]);

  const debouncedFilters = useDebounce(filters, 200);

  React.useEffect(() => {
    loadReport();
  }, [debouncedFilters]);

  React.useEffect(() => {
    if (!confirmAdjustment.loading && confirmAdjustment.data) loadReport();
  }, [confirmAdjustment.loading]);

  React.useEffect(() => {
    if (!exportData.loading) {
      if (exportData.data) {
        enqueueSnackbar(
          'Sending data export - check your emails in a few minutes.',
          { variant: 'success' },
        );
      } else if (exportData.error) {
        enqueueSnackbar('Data export unsuccessful. Please try again.', {
          variant: 'error',
        });
      }
    } else {
      enqueueSnackbar('Starting data export.');
    }
  }, [exportData.loading]);

  const currentCustomers = useCustomerSelector(loadReport);

  const oldPage = usePrevious(page);
  React.useEffect(() => {
    loadReport('table');
  }, [page, pageSize]);

  const oldSort = usePrevious(sort);
  React.useEffect(() => {
    if (!isEqual(sort, oldSort)) loadReport();
  }, [sort]);

  const ErrorFallback = ({ error, resetErrorBoundary }) => {
    return (
      <Paper
        sx={{
          height: '100%',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          minHeight: 100,
        }}
      >
        <Typography variant={'h5'}>Error loading report.</Typography>

        <Button onClick={resetErrorBoundary} startIcon={<Refresh />}>
          Try again
        </Button>
      </Paper>
    );
  };
  if (!report) return null;

  if (props.type === 'abridged') {
    return (
      <ErrorBoundary
        onReset={() => {
          loadReport();
        }}
        FallbackComponent={ErrorFallback}
      >
        <ReportGraphic
          loadFullReport={loadFullReport}
          reportSettings={reportSettings}
          onSettingsChange={settings => {
            setReportSettings(settings);
          }}
          id={props.id}
          full={false}
          report={report}
        />
      </ErrorBoundary>
    );
  }

  return (
    <>
      <FilterBar values={{}} />
      {!!report && (
        <Helmet title={get(report, 'graphic.data.title', 'All data')}></Helmet>
      )}
      <Box sx={{ mt: 2 }}>
        {props.id !== 'main-report' && !!get(report, 'graphic') && (
          <ErrorBoundary
            onReset={() => {
              loadReport();
            }}
            FallbackComponent={ErrorFallback}
          >
            <ReportGraphic
              loadFullReport={loadFullReport}
              reportSettings={reportSettings}
              id={props.id}
              full
              report={report}
              onSettingsChange={(settings, reload = false) => {
                setReportSettings({ ...settings, reload });
              }}
            />
          </ErrorBoundary>
        )}

        <Paper
          sx={{
            mb: 4,
            height: 850,
            maxHeight: '80vh',
            minHeight: 400,
            width: '100%',
          }}
        >
          <ErrorBoundary
            onReset={() => {
              loadReport('table');
            }}
            FallbackComponent={ErrorFallback}
          >
            {report.table.data && props.type === 'full' ? (
              <DataTable
                disableAdjustments={currentCustomers.length !== 1}
                onClickToExportDataSheet={ids => {
                  serveFile(`adjustments/get-import-file`, {
                    customer_id: currentCustomers[0],
                    ids,
                  })
                    .then(() => console.log('OK'))
                    .catch(e => {
                      console.log(e);
                    });
                }}
                onExportConfirm={() =>
                  dispatch(
                    actions.exportRequest({
                      reportSettings,
                      id: props.id,
                      type: 'export',
                      filters,
                      sort: sort,
                    }),
                  )
                }
                rowHeight={30}
                headerHeight={30}
                report={report.table.data}
                loading={report.table.loading || report.graphic.loading}
                onPageChange={(page, pageSize) => {
                  changePage(page, pageSize);
                }}
                onPageSizeChange={s => {
                  setPageSize(s);
                }}
                onSortChange={(sortModel: GridSortModel) => {
                  setSort(
                    sortModel.length
                      ? [sortModel[0].field, sortModel[0].sort]
                      : undefined,
                  );
                }}
                onClickToEdit={(change, ids) => {
                  dispatch(
                    lineFieldActions.adjustmentIntentRequest({
                      change,
                      ids,
                    }),
                  );
                }}
              />
            ) : (
              <Skeleton variant={'rectangular'} height={'100%'} />
            )}
          </ErrorBoundary>
        </Paper>
      </Box>
    </>
  );
}
