import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { ISupplier, ISupplierAction, SupplierApiCall } from './slice/types';
import {
  Box,
  Paper,
  Typography,
  Autocomplete,
  TextField,
  Button,
  Select,
  Grid,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { ApiCall } from '../../../types/ApiCall';
import { useSnackbar } from 'notistack';
import { useHasChanged } from '../../../utils/usePrevious';

interface Props {
  suppliers: SupplierApiCall;
  supplierActions: ApiCall;
  actionRequest: (supplierActions: ISupplierAction[]) => void;
}

export function ManageSupplier(props: Props) {
  const { suppliers, supplierActions, actionRequest } = props;
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(true);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [selectedSupplier, setSelectedSupplier] = useState<{
    id: number | null | undefined;
    name: string;
  } | null>(null);

  const [selectedLabel, setSelectedLabel] = useState<string | null>(null);
  const [selectedNames, setSelectedNames] = useState<string[] | []>([]);

  const targetSupplierList = useMemo(() => {
    return (
      suppliers.data
        // This can be removed when other actions are available for suppliers
        .filter(supplier => supplier.supplier_names.length > 1)
        .map(supplier => ({
          id: supplier.id,
          name: `[${supplier.id}] ${supplier.name} (${
            supplier.supplier_names.length
          } name${supplier.supplier_names.length > 1 ? 's' : ''} assigned)`,
        }))
    );
  }, [suppliers]);

  const supplierActionsList: ISupplierAction[] = useMemo(() => {
    const arr: ISupplierAction[] = [];
    if (!selectedSupplier) return arr;

    if (selectedLabel) {
      arr.push({
        type: 'edit-supplier-label',
        name: selectedLabel,
        supplier_id: selectedSupplier.id!,
      });
    }

    console.log(arr);
    selectedNames.forEach(name => {
      arr.push({
        type: 'reset-name',
        name: name,
        supplier_id: selectedSupplier.id!,
      });
    });

    return arr;
  }, [suppliers, selectedSupplier, selectedLabel, selectedNames]);

  const getSupplierNames = useCallback(
    id => {
      return (suppliers.data.find(s => s.id === id) || {}).supplier_names || [];
    },
    [suppliers],
  );
  const getSupplierLabel = useCallback(
    id => {
      return (suppliers.data.find(s => s.id === id) || {}).name;
    },
    [suppliers],
  );

  const submitActions = useCallback(() => {
    actionRequest(supplierActionsList);
  }, [supplierActionsList]);

  const labels = useMemo(() => {
    if (!selectedSupplier) return [];

    return getSupplierNames(selectedSupplier.id)
      .filter(name => name.name !== getSupplierLabel(selectedSupplier.id))
      .map(name => name.name);
  }, [suppliers, selectedSupplier]);

  const names = useMemo(() => {
    if (!selectedSupplier) return [];
  }, [suppliers, selectedSupplier]);

  useEffect(() => {
    setConfirmDialogOpen(false);
    setSelectedNames([]);
    setSelectedLabel(null);
  }, [selectedSupplier]);

  useEffect(() => {
    setSelectedNames(selectedNames.filter(name => name !== selectedLabel));
  }, [selectedLabel]);

  useEffect(() => {
    setSelectedLabel(
      selectedNames.find(name => name === selectedLabel) ? null : selectedLabel,
    );
  }, [selectedNames]);

  const confirmationText = useMemo(() => {
    if (!selectedLabel && selectedNames.length < 1) return '';

    const labelText = selectedLabel ? (
      <>
        Changing supplier label to: <br /> <b>{selectedLabel}</b> <br />
      </>
    ) : null;
    const unassignText =
      selectedNames.length > 0 ? (
        <>
          Unassigning supplier names: <br />
          <b>{selectedNames.join(', ')}</b>
        </>
      ) : null;

    return (
      <>
        {labelText} <br />
        {unassignText}
      </>
    );
  }, [selectedSupplier, selectedLabel, selectedNames]);

  const supplierActionsLoadingChanged = useHasChanged(supplierActions.loading);
  const suppliersLoadingChanged = useHasChanged(suppliers.loading);

  useEffect(() => {
    if (supplierActionsLoadingChanged && !supplierActions.loading) {
      setConfirmDialogOpen(false);
      enqueueSnackbar(`Supplier managed successfully`, {
        variant: 'success',
      });
      setSelectedSupplier(null);
    }
    if (suppliersLoadingChanged && !suppliers.loading) {
      setConfirmDialogOpen(false);
    }
  });

  return (
    <Box>
      <Box sx={{ p: 2 }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant={'h6'} sx={{ mb: 2 }}>
            Select supplier to manage
          </Typography>
          <Autocomplete
            sx={{ backgroundColor: 'white' }}
            renderInput={params => (
              <TextField {...params} label={'Select supplier to manage'} />
            )}
            options={targetSupplierList}
            value={selectedSupplier || null}
            fullWidth
            getOptionLabel={(option: any) => option.name ?? 'Select'}
            onChange={(ev, value) => {
              setSelectedSupplier(value);
            }}
          />
        </Box>
        <Grid container spacing={4} sx={{ pb: 4 }}>
          <Grid item xs={12} md={6} sx={{ mb: 2 }}>
            <Paper
              elevation={0}
              square
              sx={{ my: 4, p: 4, backgroundColor: `grey.A100`, height: '100%' }}
            >
              <Typography variant={'h6'} sx={{ mb: 2 }}>
                Change supplier label
              </Typography>
              <Autocomplete
                disabled={!selectedSupplier}
                sx={{ backgroundColor: 'white' }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={'Select new label for supplier'}
                  />
                )}
                options={labels}
                value={selectedLabel || null}
                fullWidth
                getOptionLabel={(option: any) => option ?? 'Select'}
                onChange={(ev, value) => {
                  setSelectedLabel(value);
                }}
              />
              <Typography variant={'body1'} sx={{ mt: 2 }}>
                You can change the label you wish to see represent this
                supplier.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} sx={{ mb: 2 }}>
            <Paper
              elevation={0}
              square
              sx={{ my: 4, p: 4, backgroundColor: `grey.A100`, height: '100%' }}
            >
              <Typography variant={'h6'} sx={{ mb: 2 }}>
                Remove/unassign supplier name
              </Typography>
              <Autocomplete
                disabled={!selectedSupplier}
                sx={{ backgroundColor: 'white' }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={'Select supplier names to unassign'}
                  />
                )}
                options={labels}
                value={selectedNames || []}
                fullWidth
                multiple
                getOptionLabel={(option: any) => option ?? 'Select'}
                onChange={(ev, value) => {
                  setSelectedNames(value);
                }}
              />
              <Typography variant={'body1'} sx={{ mt: 2 }}>
                Remove/unassign supplier names that have been added in error to
                this supplier.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          p: 2,
          borderTop: 1,
          borderColor: `grey.A100`,
          textAlign: 'right',
        }}
      >
        <Button
          variant={'contained'}
          disabled={
            !selectedSupplier || (!selectedLabel && selectedNames.length < 1)
          }
          color={'primary'}
          onClick={() => {
            setConfirmDialogOpen(true);
          }}
        >
          Save changes
        </Button>
      </Box>
      <Dialog
        open={confirmDialogOpen && !!selectedSupplier}
        fullWidth
        maxWidth="sm"
        disableEscapeKeyDown
      >
        <DialogTitle>Supplier changes - please confirm</DialogTitle>
        <DialogContent>
          <DialogContentText variant={'body1'}>
            Please review your changes below.
          </DialogContentText>
          {!!confirmationText && (
            <DialogContentText sx={{ mt: 2 }}>
              {confirmationText}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ mr: 'auto' }}
            variant="text"
            onClick={() => {
              setConfirmDialogOpen(false);
            }}
            disabled={supplierActions.loading}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              submitActions();
            }}
            disabled={supplierActions.loading}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
