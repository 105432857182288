/**
 *
 * AmendedItems
 *
 */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAmendedItemsSlice } from './slice';
import { useHistory } from 'react-router-dom';
import {
  selectCreateAdjustmentReport,
  selectLoadAdjustments,
  selectRevertAdjustments,
} from './slice/selectors';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { lineFieldActions } from '../LineAdjustmentManager/slice';
import { Add, Close, Edit } from '@mui/icons-material';
import { useConfirm } from '../../components/ConfirmDialog';
import { ManagementTable } from '../../components/ManagementTable';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { PopoverButton } from '../../components/PopoverButton';
import { AdjustmentDetails } from '../../components/AdjustmentDetails';
import { FilterBar } from '../FilterBar';
import { useQuery } from '../../../utils/query';
import { useDebounce } from '../../../utils/useDebounce';
import { usePrevious } from '../../../utils/usePrevious';
import { isEqual } from 'lodash';
import { useLocation } from 'react-router';
import { selectConfirmAdjustment } from '../LineAdjustmentManager/slice/selectors';
import { useCustomerSelector } from '../CustomerSelector/useCustomerSelector';
import { formatParsedISO } from '../../../utils/dates';
import { selectUserData } from '../AuthProvider/slice/selectors';
import { hasPermission } from '../../../utils/user';
import { UserPermission } from '../AuthProvider/slice/types';

interface Props {}

export function AmendedItems(props: Props) {
  const history = useHistory();
  const confirm = useConfirm();
  const { actions } = useAmendedItemsSlice();
  const dispatch = useDispatch();
  const loadAdjustments = useSelector(selectLoadAdjustments);
  const revertAdjustments = useSelector(selectRevertAdjustments);
  const createAdjustmentReport = useSelector(selectCreateAdjustmentReport);
  const confirmAdjustment = useSelector(selectConfirmAdjustment);
  const { query, setQuery } = useQuery();
  const getFilters = () => query.filters;
  const { search } = useLocation();
  const [filters, setFilters] = React.useState(getFilters());
  const userData = useSelector(selectUserData);

  const [selectedItems, setSelectedItems]: [
    selectedItems: number[],
    setSelectedItems: Function,
  ] = React.useState([]);

  const getAdjustments = () => {
    dispatch(actions.loadAdjustmentsRequest({ filters: getFilters() }));
  };

  React.useEffect(() => {
    getAdjustments();
    return () => {
      dispatch(actions.initialise());
    };
  }, []);

  React.useEffect(() => {
    if (!revertAdjustments.loading && revertAdjustments.data) {
      getAdjustments();
    }
  }, [revertAdjustments.loading]);

  React.useEffect(() => {
    if (!confirmAdjustment.loading && confirmAdjustment.data) {
      getAdjustments();
    }
  }, [confirmAdjustment.loading]);

  useCustomerSelector(getAdjustments);

  React.useEffect(() => {
    setFilters(getFilters());
  }, [search]);

  const debouncedFilters = useDebounce(filters, 200);
  const oldFilters = usePrevious(debouncedFilters);

  React.useEffect(() => {
    if (!isEqual(debouncedFilters, oldFilters)) {
      getAdjustments();
    }
  }, [debouncedFilters]);

  React.useEffect(() => {
    if (!createAdjustmentReport.loading) {
      if (createAdjustmentReport.data) {
        history.push(
          '/portal/report/pending-approval/', // + createAdjustmentReport.data.id,
        );
      }
    }
  }, [createAdjustmentReport.loading]);

  const openCreateAdjustmentReportDialog = () => {
    if (selectedItems.length > 0) {
      confirm({
        description: `Creating adjustment report from ${
          selectedItems.length
        } selected adjustment${selectedItems.length > 1 ? 's' : ''}`,
      }).then(() => {
        dispatch(actions.createAdjustmentReportRequest({ ids: selectedItems }));
      });
    }
  };

  const rows = loadAdjustments.data.map(item => ({
    ...item,
  }));

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          mt: 4,
          alignItems: 'center',
        }}
      >
        <Typography variant={'h1'} color={'primary'}>
          Amended items
        </Typography>
      </Box>

      <FilterBar
        values={{}}
        fields={[
          'date_range',
          'adjustment_type',
          'divider',
          'commodity_code',
          'cpc',
          'preference',
        ]}
      />

      <Paper sx={{ my: 4 }}>
        <Box sx={{ minHeight: 300 }}>
          <ManagementTable
            columns={[
              {
                label: 'Adjusted Date',
                value: row => formatParsedISO(row.adjusted_date),
              },
              {
                label: 'Line Entry Date',
                value: row => formatParsedISO(row.entry_date),
              },
              {
                value: 'line_code',
                label: 'Line Reference',
              },
              {
                value: 'change_type',
                label: 'Type',
              },
              {
                label: 'Adjustment',
                value: row => {
                  return (
                    <PopoverButton
                      sx={{ display: 'inline-block' }}
                      buttonProps={{
                        size: 'small',
                        variant: 'outlined',
                        color: 'secondary',
                      }}
                      buttonText={'Show changes'}
                      popoverContent={
                        <AdjustmentDetails actions={row.actions} />
                      }
                    />
                  );
                },
              },
              {
                value: 'metadata.created_by',
                label: 'Created by',
              },
              {
                classes: 'align-right',
                value: row => (
                  <>
                    <Tooltip title={'Revert adjustment'}>
                      <IconButton
                        disabled={
                          !hasPermission(
                            userData,
                            UserPermission.CREATE_ADJUSTMENTS,
                          )
                        }
                        edge="start"
                        onClick={() =>
                          confirm({
                            description:
                              'Reverting adjustment - this action is permanent.',
                          }).then(() =>
                            dispatch(
                              actions.revertAdjustmentsRequest({
                                ids: [row.id],
                              }),
                            ),
                          )
                        }
                        size="large"
                      >
                        <Close />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={'Edit adjustment'}>
                      <IconButton
                        disabled={
                          !hasPermission(
                            userData,
                            UserPermission.CREATE_ADJUSTMENTS,
                          )
                        }
                        onClick={() =>
                          dispatch(
                            lineFieldActions.adjustmentIntentRequest({
                              change: row.change_type,
                              ids: [row.line_item_id],
                            }),
                          )
                        }
                        size="large"
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </>
                ),
              },
            ]}
            enableCheckboxSelection
            loading={loadAdjustments.loading}
            rows={rows}
            onSelectionChange={ids => setSelectedItems(ids.map(s => +s))}
            onClick={() => {}}
            page={1}
            onChangePage={() => {}}
            rowsPerPage={1000}
            rowsPerPageOptions={[]}
            count={1000}
            loadingComponent={<LoadingIndicator minHeight={300} />}
            rowClasses={{}}
            noDataMessage={'No amended items.'}
          />
        </Box>

        <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant={'contained'}
            startIcon={<Add />}
            disabled={!selectedItems.length}
            onClick={() => openCreateAdjustmentReportDialog()}
          >
            Add selected to adjustment report
          </Button>
        </Box>
      </Paper>
    </>
  );
}
