/**
 *
 * ImportDataForm
 *
 */
import * as React from 'react';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Fab,
  MenuItem,
  Select,
  TextField,
  useTheme,
  Typography,
  Paper,
} from '@mui/material';
import { Add, AddCircle, Close } from '@mui/icons-material';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { white } from 'chalk';

interface Props {
  loading: boolean;
  progress: number;
  request: Function;
  lastDataStreamId: number | undefined;
}

export function ImportDataForm(props: Props) {
  const [stage, setStage]: [stage: number, setStage: Function] = React.useState(
    1,
  );

  const theme = useTheme();

  const [file, setFile]: [
    file: File | undefined,
    setFile: Function,
  ] = React.useState(undefined);
  const [dataStreamId, setDataStreamId]: [
    dataStreamId: number,
    setDataStreamId: Function,
  ] = React.useState(props.lastDataStreamId ?? 1);

  const [password, setPassword]: [
    password: string,
    setPassword: Function,
  ] = React.useState('');

  const submitDataStream = e => {
    if (dataStreamId) {
      setStage(2);
    }
  };

  const removeFile = () => {
    setStage(1);
    setFile(undefined);
  };

  const fileChange = e => {
    const [f] = e.target.files || e.dataTransfer.files;
    setFile(f);
    setStage(3);
  };

  const upload = () => {
    if (!props.loading) props.request(file, dataStreamId, password);
  };

  switch (stage) {
    case 1:
      return (
        <Paper square elevation={0} sx={{ p: 3, backgroundColor: 'grey.A100' }}>
          <Typography variant="h6">Select your data system</Typography>
          <Box sx={{ mt: 2, mb: 4 }}>
            <FormControl variant="outlined" fullWidth>
              <Select
                sx={{ backgroundColor: 'white' }}
                value={dataStreamId}
                onChange={e => setDataStreamId(e.target.value)}
              >
                <MenuItem value={1}>CHIEF</MenuItem>
                <MenuItem value={2}>CDS</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={submitDataStream}
          >
            Continue
          </Button>
        </Paper>
      );
    case 2:
      return (
        <Paper square elevation={0} sx={{ p: 3, backgroundColor: 'grey.A100' }}>
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              cursor: 'pointer',
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <Fab color="primary">
              <Add />
            </Fab>
            <Box
              sx={{
                ml: 4,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h4" color="primary">
                Select your data file
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Click here or drag a file on to the grey box to open
              </Typography>
            </Box>

            <input
              type="file"
              onChange={fileChange}
              style={{
                opacity: 0,
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                cursor: 'pointer',
              }}
            />
          </Box>
        </Paper>
      );

    case 3:
      return (
        <Paper
          elevation={0}
          square
          sx={{
            backgroundColor: theme.palette.grey[50],
            p: 3,
            position: 'relative',
          }}
        >
          <IconButton
            size="medium"
            color="primary"
            onClick={() => removeFile()}
            sx={{ position: 'absolute', right: 2, top: 2 }}
          >
            <Close />
          </IconButton>
          <Typography variant="h5" color={'primary'}>
            {file!.name}
          </Typography>
          <Typography variant="subtitle1">{file!.size} bytes</Typography>

          <Box sx={{ my: 4 }}>
            <Box sx={{ my: 4 }}>
              {props.loading ? (
                <progress value={props.progress} style={{ width: '100%' }} />
              ) : (
                <TextField
                  sx={{ backgroundColor: 'white' }}
                  fullWidth
                  label={'Enter Password'}
                  value={password}
                  type="text"
                  onChange={e => setPassword(e.target.value)}
                />
              )}
            </Box>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => upload()}
              disabled={props.loading}
            >
              Upload
            </Button>
          </Box>
        </Paper>
      );
  }
  return null;
}
